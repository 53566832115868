<div class="main-container">
	<div class="editor-container editor-container_classic-editor" #editorContainerElement>
		<div class="editor-container__editor">
			<ckeditor
				[config]="editorConfig" 
				[data]="content"
				(dataChange)="onChange($event)">
			</ckeditor>
		</div>
	</div>
</div>