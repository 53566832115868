import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CtaQuestion } from '@models/ComplianceTravelApproval';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { domStringToBase64 } from '@shared/utils/encode-decode/encode-decode.utils';
import { ConfigureCtaSpecificRouteRegex } from '@shared/utils/regex/routes.regex';
import { fetchBusinessUnits } from '@store/business-unit/business-unit.actions';
import { fetchCountryByCountryCode } from '@store/country/country.actions';
import { fetchCta } from '@store/cta/cta.actions';
import {
  showSuccessNotification,
  showWarningNotification,
} from '@store/header/header.actions';
import { AppState } from '@store/root/root.reducer';
import {
  getCurrentCountry,
  getRouterStateUrl,
  getTaSpecificQuestionsToSaveOrPreview,
} from '@store/root/root.selectors';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CtaSpecificActions,
  CtaSpecificFilterSelect,
  FetchPreviewFromQuestions,
  FetchQuestions,
  FetchQuestionsForConfiguration,
  FetchQuestionsForDuplication,
  FetchQuestionsForDuplicationSuccess,
  SaveQuestions,
  ctaSpecificConfigurationFormChanged,
  ctaSpecificFilterSelect,
  fetchPreviewFromQuestionsFail,
  fetchPreviewFromQuestionsSuccess,
  fetchQuestionsFail,
  fetchQuestionsForConfiguration,
  fetchQuestionsForConfigurationFail,
  fetchQuestionsForConfigurationSuccess,
  fetchQuestionsForDuplicationSuccess,
  fetchQuestionsSuccess,
} from './cta-specific.actions';

@Injectable()
export class CtaSpecificEffects {
  // Route: /configure/cta-specific/${countryCode}
  public navigateToConfigure$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store$.select(getRouterStateUrl),
        (_action, router) => router
      ),
      filter((url) => ConfigureCtaSpecificRouteRegex.test(url)),
      map<string, string>(
        (url) => url.match(ConfigureCtaSpecificRouteRegex)[1]
      ),
      mergeMap((countryCode) => {
        return [
          fetchBusinessUnits(), // For fetching the list in 'change scope'
          fetchQuestionsForConfiguration(countryCode),
          fetchCountryByCountryCode(countryCode),
        ];
      })
    )
  );

  public makeCtaSpecificConfigurationFormDirty$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CtaSpecificActions>(
        'ADD_NEW_CONDITIONAL_QUESTION',
        'ADD_NEW_OPTION',
        'ADD_NEW_QUESTION',
        'ADD_NEW_QUESTION_WITH_SCOPE',
        'CHANGE_QUESTION_TYPE',
        'CLEAR_QUESTIONS',
        'CONDITIONAL_OPTION_SELECTED',
        'CONDITIONAL_QUESTION_CHANGE',
        'CONDITIONAL_QUESTION_SELECTED',
        'EDIT_CONDITIONS',
        'EDIT_DESCRIPTION',
        'EDIT_OPTION',
        'EDIT_SCOPE',
        'REMOVE_CONDITIONAL_QUESTION',
        'REMOVE_OPTION',
        'REMOVE_QUESTION',
        'REORDER_OPTIONS',
        'REORDER_QUESTIONS',
        'USE_OR_CHANGE'
      ),
      switchMap(() => [ctaSpecificConfigurationFormChanged(true)])
    )
  );

  public fetchQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchQuestions>('FETCH_QUESTIONS'),
      switchMap(({ countryCode, travelerEmail }) =>
        this.http
          .get(`gateway/travelApprovals/questions/${countryCode}`, {
            params: {
              traveler: travelerEmail,
            },
          })
          .pipe(
            map((res: CtaQuestion[]) => fetchQuestionsSuccess(res)),
            catchError((err: HttpErrorResponse) => {
              return [
                fetchQuestionsFail(err),
                showWarningNotification(err.error.message),
              ];
            })
          )
      )
    )
  );

  public fetchQuestionsForConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchQuestionsForConfiguration>(
        'FETCH_QUESTIONS_FOR_CONFIGURATION'
      ),
      switchMap(({ countryCode, businessUnit }) => {
        var url = `travelApprovals/questions/${countryCode}/forConfiguration${
          businessUnit !== 'ALL' && businessUnit
            ? '?businessUnit=' + businessUnit
            : ''
        }`;
        return this.http.get(`gateway/${url}`).pipe(
          map((ctaQuestions: CtaQuestion[]) =>
            fetchQuestionsForConfigurationSuccess(ctaQuestions)
          ),
          catchError((err: HttpErrorResponse) => {
            return [
              fetchQuestionsForConfigurationFail(err),
              showWarningNotification(err.error.message),
            ];
          })
        );
      })
    )
  );

  public fetchQuestionsForDuplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchQuestionsForDuplication>('FETCH_QUESTIONS_FOR_DUPLICATION'),
      switchMap(({ countryCode, businessUnit, ctaDuplicatedId, traveler }) => {
        const params = traveler ? { businessUnit, traveler } : { businessUnit };

        return this.http
          .get(`gateway/travelApprovals/questions/${countryCode}`, { params })
          .pipe(
            mergeMap((res: CtaQuestion[]) => [
              fetchQuestionsForDuplicationSuccess(res, ctaDuplicatedId),
            ]),
            catchError((err: HttpErrorResponse) => {
              return [
                fetchQuestionsFail(err),
                showWarningNotification(err.error.message),
              ];
            })
          );
      })
    )
  );

  public fetchQuestionsForDuplicationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchQuestionsForDuplicationSuccess>(
        'FETCH_QUESTIONS_FOR_DUPLICATION_SUCCESS'
      ),
      switchMap(({ ctaDuplicatedId }) => {
        if (ctaDuplicatedId) {
          return [fetchCta(ctaDuplicatedId, true)];
        } else {
          return of();
        }
      })
    )
  );

  public ctaSpecificFilterSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CtaSpecificFilterSelect>('CTA_SPECIFIC_FILTER_SELECT'),
      withLatestFrom(this.store$.select(getCurrentCountry)),
      switchMap(([action, country]) =>
        action.fetch
          ? [
              fetchQuestionsForConfiguration(
                country.alpha2,
                action.businessUnit
              ),
            ]
          : []
      )
    )
  );

  public fetchQuestionsPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchPreviewFromQuestions>('FETCH_PREVIEW_FROM_QUESTIONS'),
      withLatestFrom(this.store$.select(getTaSpecificQuestionsToSaveOrPreview)),
      map(([action, ctaQuestions]) => {
        return { action, ctaQuestions };
      }),
      switchMap(({ action, ctaQuestions }) => {
        var url = `gateway/travelApprovals/questions/preview${
          action.businessUnit !== 'ALL' && action.businessUnit
            ? `?businessUnit=${action.businessUnit}`
            : ''
        }`;
        return this.http
          .post(
            url,
            encodeCtaQuestionsRequest(addAnchorLinkAttributes(ctaQuestions))
          )
          .pipe(
            map((res: CtaQuestion[]) => {
              return fetchPreviewFromQuestionsSuccess(res);
            }),
            catchError((err: HttpErrorResponse) => {
              return [
                showWarningNotification(err.error.message),
                fetchPreviewFromQuestionsFail(err),
              ];
            })
          );
      })
    )
  );

  public save$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveQuestions>('SAVE_QUESTIONS'),
      withLatestFrom(
        this.store$.pipe(select(getTaSpecificQuestionsToSaveOrPreview))
      ),
      switchMap(([action, questions]) =>
        this.http
          .put(
            `gateway/travelApprovals/questions/${action.countryCode}`,
            encodeCtaQuestionsRequest(addAnchorLinkAttributes(questions))
          )
          .pipe(
            mergeMap((res: CtaQuestion[]) => [
              showSuccessNotification(
                'You have successfully save the CTA specific questions for the country.'
              ),
              fetchQuestionsSuccess(res),
              ctaSpecificFilterSelect(action.businessUnit),
            ]),
            catchError((err: HttpErrorResponse) => {
              return [
                fetchQuestionsFail(err),
                showWarningNotification(err.error.message),
              ];
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store$: Store<AppState>
  ) {}
}

function encodeCtaQuestionsRequest(questions: CtaQuestion[]): CtaQuestion[] {
  return questions.map((question) => ({
    ...question,
    description: domStringToBase64(question.description),
  }));
}

function addAnchorLinkAttributes(questions: CtaQuestion[]): CtaQuestion[] {
  return questions.map((question) =>
    question.type === 'ACKNOWLEDGEMENT'
      ? {
          ...question,
          description: question.description.replaceAll(
            /href/g,
            'target="_blank" rel="noopener noreferrer" href'
          ),
        }
      : question
  );
}
