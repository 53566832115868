import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { isUndefined } from 'app/utils/predicates.utils';
import { CKEditor4 } from 'ckeditor4-angular';

@Component({
  selector: 'sgxb-ckeditor-wrapper',
  templateUrl: './ckeditor-wrapper.component.html',
  styleUrls: ['./ckeditor-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CkeditorWrapperComponent {
  @Input() public content = '';
  @Input() public isHidden = false;
  @Output() public contentChange = new EventEmitter<string>();

  private _editorConfig: CKEditor4.Config = undefined;
  private readonly defaultEditorConfig: CKEditor4.Config = {
    toolbar: [
      { name: 'basicStyles', items: ['Bold', 'Italic', 'Underline'] },
      {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', 'Indent', 'Outdent'],
      },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'links', items: ['Link', 'Unlink', 'Image'] },
      {
        name: 'editing',
        items: [
          'Undo',
          'Redo',
          'Find',
          'Replace',
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
        ],
      },
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
      { name: 'insert', items: ['Table', 'Blockquote'] },
    ],
    colorButton_colors: '529aff,ff563c,00d750,f98d00,000000,ffffff',
    colorButton_enableAutomatic: false,
    colorButton_enableMore: false,
    fontSize_sizes: '10/10px;12/12px;14/14px;default;18/18px;20/20px;22/22px;',
    removeButtons: 'Subscript,Superscript',
  };

  @Input()
  set editorConfig(config: CKEditor4.Config | undefined) {
    if (isUndefined(config)) {
      this._editorConfig = this.defaultEditorConfig;
    } else {
      this._editorConfig = config;
    }
  }
  get editorConfig(): CKEditor4.Config {
    return this._editorConfig;
  }

  public emitContentChanged(): void {
    this.contentChange.emit(this.content);
  }
  public onChange(content: string): void {
    this.contentChange.emit(content);
  }
}
