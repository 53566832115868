import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, EventManager } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { AppComponent } from '@core/containers/app/app.component';
import { CoreModule } from '@core/core.module';
import { ApiBaseInterceptor } from '@core/services/api-base-interceptor/api-base-interceptor.service';
import { appInitializer } from '@core/services/app-initializer/app-initializer';
import { AuthorizationInterceptor } from '@core/services/authorization-interceptor/authorization-interceptor.service';
import { CorrelationIdInterceptor } from '@core/services/correlation-id-interceptor/correlation-id-interceptor.service';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { MatomoConsentMode, NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { CustomEventManager } from '@shared/custom-event-manager';
import { SharedModule } from '@shared/shared.module';
import { CustomRouterStateSerializer } from '@shared/utils/router/router.utils';
import { AppStoreModule } from '@store';
import '../styles/styles.scss';
import { ROUTES } from './app.routing';
import { MockApiModule } from './mock-api/mock-api.module';

const devModules: NgModule['imports'] = [];
if (window.configurations.backend.useMock) {
  devModules.push(MockApiModule);
}

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
    }),
    AppStoreModule.forRoot(),
    CoreModule,
    SharedModule.forRoot(),
    NgxMatomoTrackerModule.forRoot({
      disabled: !window.configurations.production,
      siteId: window.configurations.piwik.id,
      trackerUrl: window.configurations.piwik.base,
      enableLinkTracking: true,
      requireConsent: MatomoConsentMode.COOKIE,
    }),
    ...devModules,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [Store],
    },
    { provide: EventManager, useClass: CustomEventManager },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: ApiBaseInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
